import React, { useState, useEffect, useMemo } from "react";
import ExportApi from "../api/ExportApi";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";

const UpdateBulkPrice = () => {
  const [seriesData, setSeriesData] = useState([]);
  const [filterLoader, setFilterLoader] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [progress, setProgress] = useState(0);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [inputValues, setInputValues] = useState({});

  const BATCH_SIZE = 5;

  // Fetch all series data
  const fetchAllSeries = async () => {
    setFilterLoader(true);
    try {
      const response = await ExportApi.getallseriesdata();
      setSeriesData(response.data.Data);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Error fetching data");
    } finally {
      setFilterLoader(false);
    }
  };

  useEffect(() => {
    fetchAllSeries();
  }, []);

  // Filter out original data based on edited values
  const filterOriginalData = (originalData, editedData) => {
    const editedProductIds = new Set(Object.keys(editedData));
    return originalData.filter((item) => !editedProductIds.has(item.productid));
  };

  // Handle input changes
  const handleEdit = (productId, field, value) => {
    // Only log if the value is not "0"
    if (value !== "0") {
      console.log(productId, field, value, "handledit>>12");
    }
  
    // Update inputValues only if value is not "0"
    if (value !== "0" || value === "") {
      setInputValues((prev) => ({
        ...prev,
        [productId]: {
          ...prev[productId],
          [field]: value === "" ? null : Number(value),
        },
      }));
    }
  };
  

 
const updateBulk = async () => {
  setUpdating(true);
  setProgress(0);
  try {
    // Create combined data by merging seriesData and inputValues
    const combinedData = seriesData.map((series) => {
      const updatedValues = inputValues[series.productid];
      // Merge updated values if they exist, otherwise return the original series data
      return updatedValues ? { ...series, ...updatedValues } : series;
    });
    console.log(combinedData,"combinedData12");
 

    const totalBatches = Math.ceil(combinedData.length / BATCH_SIZE);
    console.log(totalBatches,"totalBatches1")

    for (let batchNumber = 0; batchNumber < totalBatches; batchNumber++) {
      const startIndex = batchNumber * BATCH_SIZE;
      const endIndex = startIndex + BATCH_SIZE;
      const batchData = combinedData.slice(startIndex, endIndex);

      // Send the entire batch of combined data
      const response = await ExportApi.Updatebulkproduct(batchData);
      if (response?.data?.message === "Product data updated successfully") {
        setProgress(((batchNumber + 1) / totalBatches) * 100);
      }
    }

    toast.success("All data updated successfully!");
    fetchAllSeries();
  } catch (error) {
    console.error("Bulk update error:", error);
    toast.error("An error occurred while updating data.");
  } finally {
    setUpdating(false);
  }
};




  // Handle sorting
  const handleSort = (column) => {
    const direction =
      sortConfig.key === column && sortConfig.direction === "ascending"
        ? "descending"
        : "ascending";
    setSortConfig({ key: column, direction });
  };

  // Sort data
  const sortedData = useMemo(() => {
    const sortableData = [...seriesData];
    if (sortConfig.key) {
      sortableData.sort((a, b) => {
        const aValue = a[sortConfig.key] || 0;
        const bValue = b[sortConfig.key] || 0;
        return sortConfig.direction === "ascending"
          ? aValue - bValue
          : bValue - aValue;
      });
    }
    return sortableData;
  }, [seriesData, sortConfig]);

  // Validate input
  const validateInput = (value) => {
    const isValidNumber = /^\d*\.?\d*$/.test(value);
    return value === "" || isValidNumber;
  };

  return (
    <div>
      <h2>Update Bulk Price</h2>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {[
                "series",
                "benchmark_score",
                "used_house_ask",
                "used_house_bid",
                "new_second_hand_house_ask",
                "new_second_hand_house_bid",
              ].map((header) => (
                <TableCell key={header} style={{ fontWeight: "bold" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {header
                      .replace(/_/g, " ")
                      .replace(/\b\w/g, (c) => c.toUpperCase())}
                    <div onClick={() => handleSort(header)}>
                      <SortIcon />
                    </div>
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {filterLoader ? (
              <TableRow>
                <TableCell colSpan={6} style={{ textAlign: "center" }}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              sortedData.map((series) => (
                <TableRow key={series.productid}>
                  <TableCell>{series.series || ""}</TableCell>
                  {[
                    "benchmark_score",
                    "used_house_ask",
                    "used_house_bid",
                    "new_second_hand_house_ask",
                    "new_second_hand_house_bid",
                  ].map((field) => (
                    <TableCell key={field}>
                      <input
                        type="text"
                        value={
                          inputValues[series.productid]?.[field] !== undefined
                            ? inputValues[series.productid][field] || ""
                            : series[field] || ""
                        }
                        onChange={(e) => {
                          const value = e.target.value;
                          if (validateInput(value)) {
                            handleEdit(series.productid, field, value);
                          }
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "-") {
                            e.preventDefault();
                          }
                        }}
                        className="bulktablerow"
                      />
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>

        <div className="d-flex align-items-center justify-content-center" style={{ marginTop: '20px' }}>
          <br></br>
          <br></br>
          <Button
            variant="contained"
            color="primary"
            onClick={updateBulk}
            disabled={updating}
          >
            {updating ? "Updating..." : "Update"}
          </Button>
          {updating && (
            <div style={{ display: "flex", alignItems: "center", marginLeft: "10px" }}>
              <CircularProgress variant="determinate" value={progress} />
              <Typography variant="body2" color="textSecondary">{`${Math.round(progress)}%`}</Typography>
            </div>
          )}
        </div>
      </TableContainer>
      <br></br>
      <br></br>
    </div>
  );
};

// Sort Icon component
const SortIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="currentColor"
    className="bi bi-chevron-expand"
    viewBox="0 0 16 16"
  >
    <path
      fillRule="evenodd"
      d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
    />
  </svg>
);

export default UpdateBulkPrice;
